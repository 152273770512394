//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

import constant from '~/const';

export default {
  data() {
    return {
      groupToShow: '',
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader', 'generalProgress']),
    ...mapState('user', ['user']),
    ...mapState('userNotifications', [
      'userNotificationSettings',
      'notificationTypeList',
      'notificationServiceTypeList',
      'notificationGroupList',
    ]),

    notificationGroupMenuList() {
      const { notificationGroupList } = this;
      return [
        {
          group: '',
          caption: 'Show all',
        },
        ...notificationGroupList,
      ];
    },

    notificationGroupListFiltered() {
      const { notificationGroupList, groupToShow } = this;
      if (!groupToShow) {
        return notificationGroupList;
      }
      return notificationGroupList.filter((e) => e.group === groupToShow);
    },
  },

  async mounted() {
    this.setGeneralLoader(true);
    await this.getUserNotificationSettings();
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setGeneralProgress', 'setSuccessNotification']),
    ...mapActions('userNotifications', [
      'getUserNotificationSettings',
      'updateUserNotificationsSettings',
      'setUserNotificationSettings',
      'updateUserEmailBodyType',
    ]),

    getNotificationsByGroup(group) {
      const { notificationTypeList, notificationGroupList } = this;
      const { notificationIds } = notificationGroupList.find((e) => e.group === group);
      return notificationTypeList.filter((e) => notificationIds.includes(e.id));
    },

    getNotificationValue(notificationType, serviceType) {
      const { userNotificationSettings } = this;
      return userNotificationSettings.find((e) => (
        e.notification === notificationType && e.notificationServiceType === serviceType
      ));
    },

    getNotificationIgnored(notificationType, serviceType) {
      const notification = this.getNotificationValue(notificationType, serviceType);
      if (notification) {
        return notification.ignored;
      }
      return true;
    },

    getNotificationAllowed(notificationType, serviceType) {
      const notification = this.getNotificationValue(notificationType, serviceType);
      if (notification) {
        return notification.allowed;
      }
      return false;
    },

    async setNotification(notificationType, serviceType, value) {
      const { userNotificationSettings } = this;
      const notification = userNotificationSettings.find((e) => (
        e.notification === notificationType && e.notificationServiceType === serviceType
      ));
      notification.ignored = !value;
      await this.setUserNotificationSettings(userNotificationSettings);
      this.applyUserNotificationSettings();
    },

    async applyUserNotificationSettings() {
      this.setGeneralProgress(true);
      await this.updateUserNotificationsSettings();
      this.setGeneralProgress(false);
    },

    isSelectAllServices(notificationType) {
      const { userNotificationSettings } = this;
      const current = userNotificationSettings
        .filter((e) => e.notification === notificationType && e.allowed);
      if (!current.length) {
        return false;
      }
      return current.every((e) => !e.ignored);
    },

    async selectAllServices(notificationType, value) {
      const { userNotificationSettings } = this;
      userNotificationSettings.forEach((e) => {
        if (e.notification === notificationType && e.allowed) {
          e.ignored = !value;
        }
      });
      await this.setUserNotificationSettings(userNotificationSettings);
      this.applyUserNotificationSettings();
    },

    isSelectAllNotifications(group, serviceType) {
      const { userNotificationSettings } = this;
      const { notificationIds } = this.notificationGroupList.find((e) => e.group === group);
      const current = userNotificationSettings
        .filter((e) => (
          notificationIds.includes(e.notification)
          && e.notificationServiceType === serviceType
          && e.allowed
        ));
      if (!current.length) {
        return false;
      }
      return current.every((e) => !e.ignored);
    },

    async selectAllNotifications(group, serviceType, value) {
      const { userNotificationSettings } = this;
      const { notificationIds } = this.notificationGroupList.find((e) => e.group === group);
      userNotificationSettings.forEach((e) => {
        if (
          notificationIds.includes(e.notification)
          && e.notificationServiceType === serviceType
          && e.allowed
        ) {
          e.ignored = !value;
        }
      });
      await this.setUserNotificationSettings(userNotificationSettings);
      this.applyUserNotificationSettings();
    },

    isSelectGroup(group) {
      const { userNotificationSettings } = this;
      const { notificationIds } = this.notificationGroupList.find((e) => e.group === group);
      const current = userNotificationSettings.filter((e) => (
        notificationIds.includes(e.notification)
        && e.allowed
      ));
      if (!current.length) {
        return false;
      }
      return current.every((e) => !e.ignored);
    },

    async selectGroup(group, value) {
      const { userNotificationSettings } = this;
      const { notificationIds } = this.notificationGroupList.find((e) => e.group === group);
      userNotificationSettings.forEach((e) => {
        if (
          notificationIds.includes(e.notification)
          && e.allowed
        ) {
          e.ignored = !value;
        }
      });
      await this.setUserNotificationSettings(userNotificationSettings);
      this.applyUserNotificationSettings();
    },

    filterGroup(group) {
      this.groupToShow = group;
    },

    async updateBodyType(value) {
      const { id } = this.user;
      this.setGeneralLoader(true);
      await this.updateUserEmailBodyType({
        userId: id,
        emailBodyType: value,
      });
      this.setGeneralLoader(false);
      this.setSuccessNotification('Email body type updated');
    },
  },
};
